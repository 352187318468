<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties
        v-model="control"
        :hasExpression="true"
        :allowedTypes="['bool', 'float', 'int', 'string']"
      />
      <ControlSpecificPanel :control="control">
        <DataFormatInput v-model="control.format" :control="control" />
        <div class="form-group">
          <label for="defaultValue">
            {{ $t("default_value") }}
            <ToolTip :title="$t('hints.default_value')" />
          </label>
          <div class="input-group">
            <input
              type="text"
              id="defaultValue"
              class="form-control"
              v-model="defaultValue"
              ref="inpDefaultValue"
            />
            <div
              class="input-group-addon btn"
              @click.stop.prevent="
                defaultValue = '';
                $refs.inpDefaultValue.focus();
              "
            >
              <i class="fa fa-close"></i>
            </div>
          </div>
        </div>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import DataFormatInput from "@/components/synoptic/property-editor/controls/data-format-input.vue";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "FormattedDisplayValue",
  components: {
    ControlBaseProperties,
    ControlSpecificPanel,
    DataFormatInput,
    ToolTip
  },
  extends: BaseControl,
  data() {
    return {
      control: {}
    };
  },
  computed: {
    expression: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "expression", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.expression || "";
      }
    },
    defaultValue: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "defaultValue", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.defaultValue ?? "-";
      }
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: this.control,
          noMerge: true
        });
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    updateControl(value) {
      this.$store.dispatch("synoptic/updateControl", value);
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>
